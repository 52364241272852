<template>
  <div>
    <input
      :id="id"
      v-model="model"
      type="checkbox"
      class="checkbox"
      :value="value"
    />
    <label :for="id" class="flex items-center"
      ><span
        class="mr-2 flex h-4 w-4 items-center justify-center rounded border border-black-100 bg-white-100 text-white-100"
        :class="[getClass, { red: redCheckbox }]"
      >
        <!-- :class="{ '': isChecked }" -->
        <SvgIcon name="tick" />
      </span>
      <span class="flex items-center text-black-56">{{ label }}</span></label
    >
  </div>
</template>

<script setup lang="ts">
type Props = {
  modelValue: boolean | string[];
  value?: boolean | string | null;
  label?: string;
  // unique string
  id?: string;
  redCheckbox?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  label: '',
  value: null,
  id: '',
  redCheckbox: false,
});

type Emits = {
  (e: 'update:modelValue', value: typeof props.modelValue): void;
};

const emit = defineEmits<Emits>();

const { modelValue: model } = useVModels(props, emit);

const getClass = computed(() => {
  if (props.modelValue) {
    if (props.redCheckbox) return 'bg-collection border-collection';
    return 'bg-black-100 border-black-100';
  }
  return 'bg-white-100 border-black-100';
});
</script>

<style lang="scss">
.checkbox {
  display: none;
}
.checkbox:checked + label > span {
  @apply bg-black-100;
}
.checkbox:checked + label > span.red {
  @apply bg-collection;
}
</style>
